<template>
	<div class="creatGoodDetails" v-loading="pageLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
			<!-- 基本信息 -->
			<div class="baseInformtion">
				<div class="infortion">基本信息</div>
				<el-form-item label="商品类型">
					<el-radio v-model="ruleForm.outGoodsType" disabled :label="1">直冲</el-radio>
                    <el-radio v-model="ruleForm.outGoodsType" disabled :label="2">卡券</el-radio>
				</el-form-item>
				<el-form-item label="商品编码" prop="goodsCode" v-if="ruleForm.goodsCode">
					<el-input v-model="ruleForm.goodsCode" size="small" style="width:350px" disabled></el-input>
					<div class="textColor">管理商品编码，可帮助你在商品列表中快速查询商品</div>
				</el-form-item>
				<el-form-item label="商品条码" prop="barCode" v-if="goodsSpecValueList.length == 0">
					<el-input v-model="ruleForm.barCode" size="small" style="width:350px"></el-input>
				</el-form-item>
                <el-form-item label="外部商品ID" prop="barCode">
					<el-input v-model="ruleForm.outGoodsId" disabled size="small" style="width:200px"></el-input>
				</el-form-item>
                <el-form-item label="外部SKUID" prop="barCode">
					<el-input v-model="ruleForm.outSkuId" disabled size="small" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item label="商品名称" prop="goodsName">
					<el-input v-model="ruleForm.goodsName" style="width:500px" maxlength="60" placeholder="最多输入60个字"></el-input>
				</el-form-item>
				<el-form-item label="广告语" prop="advertisementWords">
					<el-input type="textarea" :rows="2" v-model="ruleForm.advertisementWords" style="width:500px" maxlength="300"
					placeholder="最多输入300个字"></el-input>
					<div class="textColor">商品卖点将在商品详情页显示，最多允许输入300个字</div>
				</el-form-item>

				<div class="filter-container">
					<el-form-item label="商品图片" prop="goodsImgUrls">
						<!-- <div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>商品图片</div> -->
						<div class="filter-item" style="vertical-align: top;">
							<upload-img @transPicture="transPictureFun" :AttachedImgUrls="ruleForm.goodsImgUrls" :Type="1"></upload-img>
							<div class="textColor" style="margin-top:20px">建议尺寸：800*800像素，拖拽图片可以调整顺序，排在第1位的图片将作为商品主图，用于显示在商品列表或分享等；图片最多上传10张</div>
						</div>
					</el-form-item>
				</div>
                <el-form-item
                    label="LOGO："
                    :inline-message="true"
                    prop="brandLogo"
                >
                    <el-upload
                        class="avatar-uploader"
                        :action="imgApi"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        :before-upload="beforeAvatarUpload1"
                    >
                        <img
                            v-if="ruleForm.brandLogo"
                            :src="ruleForm.brandLogo+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
                            style="width:146px;height:146px;"
                            class="avatar"
                        />
                        <i
                            v-else
                            class="el-icon-plus"
                            style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"
                        ></i>
                    </el-upload>
                </el-form-item>

				<el-form-item label="主图视频">
					<div class="videoBox">
						<el-button type="text" style="font-size: 12px;" @click="deleteVideo" :disabled="!ruleForm.mainVideoUrl">删除视频</el-button>
						<el-upload :action="videoApi" :show-file-list="false" :on-success="handleVideoSuccess" list-type="picture-card"
						:before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess">
							<video v-if="ruleForm.mainVideoUrl" :src="ruleForm.mainVideoUrl" class="avatar" controls="controls" style="width:148px;height:148px">您的浏览器不支持视频播放</video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div slot="tip" class="el-upload__tip">建议30S以内视频，建议使用1：1或16：9比例视频；视频大小请控制在30M以内</div>
						</el-upload>
					</div>
				</el-form-item>

			</div>

			<!-- 销售信息 -->
			<!-- 单品的 -->
			<div class="baseInformtion">
				<div class="infortion">销售信息</div>

				<div>
                    <el-form-item label="库存" prop="totalStock">
						<el-input v-model="ruleForm.totalStock" disabled style="width:150px" ></el-input>
					</el-form-item>
					<el-form-item label="销售价" prop="salePrice" v-if="goodsSpecValueList.length == 0" :rules="rules.salePrice">
						<el-input v-model="ruleForm.salePrice" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<el-form-item label="划线价" prop="originalPrice" :rules="[{required:true,message:'划线价不能为空',trigger: 'blur'}]">
						<el-input v-model="ruleForm.originalPrice" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<el-form-item label="成本价">
						<el-input v-model="ruleForm.costPrice" disabled style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<!-- 当是分销商品时库存不可编辑 -->
					
					<el-form-item label="上架时间">
						<el-radio-group v-model="ruleForm.startSaleTimeType">
							<el-form-item label-width="0">
								<el-radio :label="1">立即上架售卖</el-radio>
							</el-form-item>
							<el-form-item label-width="0" prop="timingSaleTime" :rules="ruleForm.startSaleTimeType==2 ?[{required:true,message:' ',trigger: 'blur'}]:[]">
								<el-radio :label="2">自定义上架时间</el-radio>
								<el-date-picker v-model="ruleForm.timingSaleTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"
								v-if="ruleForm.startSaleTimeType== 2"></el-date-picker>
							</el-form-item>
							<el-form-item label-width="0">
								<el-radio :label="3">暂不售卖，放入仓库</el-radio>
							</el-form-item>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="下架时间" prop="timingOffTime" :rules="ruleForm.endSaleTimeType?[{required:true,message:' ',trigger: 'blur'}]:[]">
						<el-checkbox v-model="ruleForm.endSaleTimeType">开启</el-checkbox>
						<el-date-picker v-model="ruleForm.timingOffTime" type="datetime" placeholder="选择日期" v-if="ruleForm.endSaleTimeType"
						value-format="yyyy-MM-dd HH:mm:ss" style="margin-left:20px;"></el-date-picker>
					</el-form-item>

					<el-form-item label="每人限购">
						<el-radio-group v-model="ruleForm.saleLimitType">
							<el-form-item label-width="0">
								<el-radio :label="1">不限购</el-radio>
							</el-form-item>
							<el-form-item label-width="0" prop="timeLimitTime" :rules="ruleForm.saleLimitType == 2?[{required:true,message:' ',trigger: 'blur'}]:[]">
								<el-radio :label="2">限时限购</el-radio>
								<el-date-picker v-model="ruleForm.timeLimitTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" v-if="ruleForm.saleLimitType == 2" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
							</el-form-item>
							<el-form-item label-width="0" prop="saleLimitQuantity" :rules="ruleForm.saleLimitType == 3?[{required:true,message:' ',trigger: 'blur'}]:[]">
								<el-radio :label="3">永久限购</el-radio>
								<el-input v-model="ruleForm.saleLimitQuantity" style="width:200px;" input="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
								v-if="ruleForm.saleLimitType == 3"></el-input>
							</el-form-item>
						</el-radio-group>
					</el-form-item>
				</div>
			</div>
			<!-- 收益与提成 -->
			<div class="baseInformtion">
				<div class="infortion">收益与提成</div>
				<el-form-item label="是否计入管理奖励">
					<el-radio v-model="ruleForm.manageAwardFlag" :label="1">是</el-radio>
					<el-radio v-model="ruleForm.manageAwardFlag" :label="0">否</el-radio>
				</el-form-item>
				<el-form-item v-if="ruleForm.manageAwardFlag" label="管理奖励收益比" prop="manageProfitConfig" :rules="ruleForm.manageAwardFlag?[{required:true,message:' ',trigger: 'blur'},rules.manageProfitConfig]:[]">
					<el-input v-model="ruleForm.manageProfitConfig" style="width:100px;" @input="formatParseInt(ruleForm,'manageProfitConfig')">
					</el-input> %
				</el-form-item>
				<el-form-item label="是否计入高管奖励">
					<el-radio v-model="ruleForm.familyAwardFlag" :label="1">是</el-radio>
					<el-radio v-model="ruleForm.familyAwardFlag" :label="0">否</el-radio>
				</el-form-item>

				<el-form-item label="推手收益" v-if="ruleForm.pusherProfitConfigs.length">
					<el-table :data="ruleForm.pusherProfitConfigs" style="width:1100px" :loading="loading">
						<el-table-column prop="pusherLabel" label="级别"></el-table-column>
						<el-table-column prop="name" label="自营销售收益">
							<template slot-scope="scope">
								<el-form-item label-width="0" :inline-message="true" :prop="'pusherProfitConfigs.' + scope.$index + '.selfSaleProfit'"
								:rules="rules.selfSaleProfit">
									商品购买数量
									<span style="margin:0 7px">*</span>
									<el-input placeholder="0" v-model="scope.row.selfSaleProfit" style="width:80px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>元/件
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="address" label="直推销售收益">
							<template slot-scope="scope">
								<el-form-item label-width="0" :inline-message="true" :prop="'pusherProfitConfigs.' + scope.$index + '.pushSaleProfit'"
								:rules="rules.pushSaleProfit">
									商品购买数量
									<span style="margin:0 7px">*</span>
									<el-input placeholder="0" v-model="scope.row.pushSaleProfit" style="width:80px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>元/件
								</el-form-item>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</div>

			<!-- 商品详情 -->
			<div class="baseInformtion" style="margin-bottom: 150px;">
				<div class="infortion">商品详情</div>
				<div class="baseBottom-box" style="margin-bottom:150px;margin-left:150px">
					<!-- max-height:700px; -->
					<quill-editor ref="myTextEditor" v-model="ruleForm.goodsDesc" :options="quillOption" style="width:80%;min-height: 300px;height:650px;"></quill-editor>
				</div>
			</div>

			<!-- 底部保存 -->
			<div class="bottom-save-btn">
				<el-button style="width:240px" @click="canselGoods">取消</el-button>

				<buttonPermissions :datas="'editGoodDetails'" v-if="$route.query.id > 0">
					<el-button style="width:240px;margin:0 10px 0 20px" @click="submitForm()" :loading="loading">保存</el-button>
				</buttonPermissions>
				<buttonPermissions :datas="'creatGoodDetails'" v-else>
					<el-button style="width:240px;margin:0 10px 0 20px" @click="submitForm()" :loading="loading">保存</el-button>
				</buttonPermissions>
			</div>
		</el-form>
	</div>
</template>

<script>
	import {
		productSave,
		productEdit,
		productInfo,
		categoryList
	} from '@/api/goods'

	import config from '@/config/index'
	import {
		quillEditor
	} from 'vue-quill-editor'
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	import quillConfig from '@/components/quill-config.js'
	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {
			quillEditor,
			uploadImg,
			buttonPermissions
		},
		data() {

			var checkSellRewardMoney = (rule, value, callback) => {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
			};

			var checkRecommendSellRewardMoney = (rule, value, callback) => {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
			};

			var singleStockCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('库存最小为0，整数'));
				} else {
					return callback();
				}
			};
			//管理奖不能大于1，不能小于0
			var checkManageProfitConfig = (rule, value, callback) => {
				console.log(value);
				if (!value && value !== 0) {
					return callback(new Error('管理奖最小为0！'));
				} else if (value > 100) {
					return callback(new Error('管理奖最大为100%！'));
				} else {
					return callback();
				}
			};
			return {
				pageLoading: false,
				value: [],
				batchType: 0, //批量修改类型
				bathPriceVisise: false, //批量修改开关
				bathNumbers: null, //批量修改的值
				productList: [], //商品供应商数据
				quillOption: quillConfig, //富文本

				ruleForm: {
					isEnableShoppingCard: false,
                    barCode: null,
                    outGoodsType:1,    //外部商品类型
                    outGoodsId:'',  //外部商品ID
                    outSkuId:'',  //外部SKUID
					pusherProfitConfigs: [
                        // {
						// 	pusherLabel: '会员卡',
						// 	pusherLevel: '1',
						// 	selfSaleProfit: null,
						// 	pushSaleProfit: null,
						// },
						// {
						// 	pusherLabel: '银卡',
						// 	pusherLevel: '2',
						// 	selfSaleProfit: null,
						// 	pushSaleProfit: null,
						// },
						{
							pusherLabel: '金卡',
							pusherLevel: '3',
							selfSaleProfit: null,
							pushSaleProfit: null,
						},
						{
							pusherLabel: '铂金卡',
							pusherLevel: '4',
							selfSaleProfit: null,
							pushSaleProfit: null,
						},
						{
							pusherLabel: '钻石卡',
							pusherLevel: '5',
							selfSaleProfit: null,
							pushSaleProfit: null,
						},
						{
							pusherLabel: '黑金卡',
							pusherLevel: '6',
							selfSaleProfit: null,
							pushSaleProfit: null,
						},
					],
					goodsCode: '',
					goodsName: '',
					advertisementWords: '',
                    goodsImgUrls: [],
                    brandLogo:'',    //品牌logo
					mainVideoUrl: '',
					supplierId: '',
					originalPrice: null,
					freightCalMethod: 1,
					freightTemplateId: null,
					goodsDesc: null,
					salePrice: null,
					totalStock: null,
					warningStock: 0,
					costPrice: '', //成本价
					isShowStock: false, //库存是否显示
					isShowSales: 0, //销量是否显示
					stockReduceMethod: 1, //库存计算方式，1下单减库存，2支付减库存
					startSaleTimeType: 1, //上架时间选择，1立即上架售卖，2自定义上架时间，3暂不售卖。
					timingSaleTime: '', //定时开售 定时开售结束时间

					endSaleTimeType: false, //是否开启自动下架
					timingOffTime: '', //自动下架时间

					saleLimitType: 1, //限购类型， 1 不限购 2 限时限购 3 永久限购
					timeLimitTime: '', //限时限购时间
					saleLimitQuantity: '', //永久限购数量
					afterSaleServiceSettings1: false, //是否允许退款
					afterSaleServiceSettings2: false, //是否允许退款退货
					afterSaleServiceSettings3: false, //允许换货
					isOverseas: false, //是否海外商品
					isNeedCardImg: false, //海外商品购买时是否需要身份证图片

					freightPrice: 0, //全国统一运费
					stock: '', //库存
					goodsSpecList: [], //规格数据
					showGoodsSpecList: [], //显示的规格数据
					copyGoodsSpecList: [], //保存一个当前数据
					unit: '', //库存单位
					virtualSoldCount: 0, //虚拟销量

					manageAwardFlag: 1, //是否计算管理奖 0否 1是
					familyAwardFlag: 1, //是否计算家族奖 0否 1是
					manageProfitConfig: 3, //商品在此订单详情的管理奖励配置
				},
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				videoApi: config.UPLOAD_VIDEO,
				rules: {
					goodsName: [{
							required: true,
							message: '请输入商品名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
					salePrice: [{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}],
					totalStock: [{
						required: true,
						validator: singleStockCheck,
						trigger: 'blur'
					}],
					goodsImgUrls: [{
						type: 'array',
						required: true,
						message: '商品图片至少上传1张',
						trigger: 'change'
					}],
					selfSaleProfit: {
						validator: checkSellRewardMoney,
						trigger: 'blur'
					},
					pushSaleProfit: {
						validator: checkRecommendSellRewardMoney,
						trigger: 'blur'
					},
					manageProfitConfig: {
						validator: checkManageProfitConfig,
						trigger: 'blur'
					},
				},
				goodsSpecValueList: [],
				speceTitleData: [],
				specevalData: [],
				loading: false,
				total: 0, //规格列表数据总数
				pageNo: 1, //规格列表页数
				pageSize: 10, //规格列表每页数量
			}
		},
		beforeMount() {
			this.getInfor();
		},
		mounted() {
			this.$refs.myTextEditor.quill.enable(false);
		},

		methods: {
			deleteVideo() {
				this.ruleForm.mainVideoUrl = ''
			},

			// 初始化数据
			async startData() {
				let _this = this;
				try {
					if (_this.$route.query.id > 0) {
						let results = await productInfo({
							id: _this.$route.query.id
						})
						if (results.success) {
							let data = results.data;
							// console.log(data);
							_this.ruleForm.advertisementWords = data.advertisementWords;
							_this.ruleForm.barCode = data.barCode;
                            _this.ruleForm.costPrice = data.costPrice;
                            _this.ruleForm.outGoodsType = data.outGoodsType;
                            _this.ruleForm.outGoodsId = data.outGoodsId;
                            _this.ruleForm.outSkuId = data.outSkuId;
							_this.ruleForm.freightCalMethod = data.freightCalMethod;
							_this.ruleForm.freightPrice = data.freightPrice;
							_this.ruleForm.freightTemplateId = data.freightTemplateId;
							_this.ruleForm.goodsCode = data.goodsCode;
							_this.ruleForm.goodsImgUrls = data.goodsImgUrls;
							_this.ruleForm.goodsName = data.goodsName;
							_this.ruleForm.isEnableShoppingCard = false;
							_this.ruleForm.isShowStock = data.isShowStock;
							_this.ruleForm.isShowSales = data.isShowSales;
							_this.ruleForm.mainVideoUrl = data.mainVideoUrl;
							_this.ruleForm.originalPrice = data.originalPrice;
							_this.ruleForm.salePrice = data.salePrice;
							_this.ruleForm.startSaleTimeType = data.startSaleTimeType;
							_this.ruleForm.supplierId = data.supplierId;
							_this.ruleForm.timingSaleTime = data.timingSaleStartTime;
							_this.ruleForm.totalStock = data.totalStock;
							_this.ruleForm.unit = data.unit;
							_this.ruleForm.warningStock = data.warningStock;
							_this.ruleForm.goodsDesc = data.goodsDesc;
							_this.ruleForm.virtualSoldCount = data.virtualSoldCount;
							_this.ruleForm.saleLimitType = data.saleLimitType;
							_this.ruleForm.saleLimitQuantity = data.saleLimitQuantity;
							_this.ruleForm.timingOffTime = data.timingOffTime;
							_this.ruleForm.isOverseas = false;
							_this.ruleForm.isNeedCardImg = data.isNeedCardImg;
							_this.ruleForm.manageAwardFlag = data.manageAwardFlag; //是否计算管理奖 0否 1是
							_this.ruleForm.familyAwardFlag = data.familyAwardFlag; //是否计算家族奖 0否 1是
							_this.ruleForm.manageProfitConfig = data.manageProfitConfig * 100; //商品在此订单详情的管理奖励配置
							_this.ruleForm.brandLogo = data.brandLogo; //商品logo

							//处理限时限购时间
							if (data.timeLimitStartTime && data.timeLimitEndTime) {
								_this.ruleForm.timeLimitTime = [data.timeLimitStartTime, data.timeLimitEndTime];
							}
							if (data.timingOffTime) {
								_this.ruleForm.timingOffTime = data.timingOffTime;
								_this.ruleForm.endSaleTimeType = true;
							}
							//处理推手收益
							if (data.pusherProfitConfigs) {
								data.pusherProfitConfigs.map((item, index) => {
                                    if (index != 0 && index != 1) {
                                        _this.ruleForm.pusherProfitConfigs[index - 2].pushSaleProfit = item.pushSaleProfit;
                                        _this.ruleForm.pusherProfitConfigs[index - 2].selfSaleProfit = item.selfSaleProfit;
                                    }
								})
							}
							this.pageLoading = true;
							//处理规格名数据
							if (data.goodSpecsList.length) {
								this.goodsSpecValueList = data.goodSpecsList.map(item => {
									item.guid = _this.guid();
									let tempSpecValueName = []
									item.specValues.map((specValue, index) => {
										tempSpecValueName.push({
											specValueName: specValue,
											imgUrl: item.hasImg ? item.specImgUrls[index] : ''
										})
									})
									item.specValues = tempSpecValueName;
									return item;
								})
								this.brothSpece();
								
							}
							try {
								//处理售后服务数据
								this.ruleForm.afterSaleServiceSettings1 = data.afterSaleServiceSettings.indexOf(1) != -1;
								this.ruleForm.afterSaleServiceSettings2 = data.afterSaleServiceSettings.indexOf(2) != -1;
								this.ruleForm.afterSaleServiceSettings3 = data.afterSaleServiceSettings.indexOf(3) != -1;

								//处理商品标签
								let goodsTags = JSON.parse(data.goodsTags);
								console.log(goodsTags);
								goodsTags.map(item => {
									this.ruleForm['tag' + item] = true;
								})
							} catch (error) {
								console.log(error);
							}
						}

					}else if (_this.$route.query.row) {
                        let row = JSON.parse(decodeURIComponent(_this.$route.query.row));
                        console.log(row);

                        _this.ruleForm.outGoodsType = row.type;
                        _this.ruleForm.outGoodsId = row.outGoodsId;
                        _this.ruleForm.outSkuId = row.outSkuId;
                        _this.ruleForm.costPrice = row.costPrice;
                        _this.ruleForm.goodsImgUrls = [row.logo];
                        _this.ruleForm.goodsName = row.goodsName;
                        _this.ruleForm.originalPrice = row.originalPrice;
                        _this.ruleForm.salePrice = row.salePrice;
                        _this.ruleForm.totalStock = row.stock;
                        _this.ruleForm.goodsDesc = row.help;
                    }
				} catch (e) {
					console.log(e)
				} finally {
					this.$nextTick(function() {
						if (this.$refs.myTextEditor.quill) {
							this.$refs.myTextEditor.quill.enable(true);
							this.$refs.myTextEditor.quill.blur();
							window.scrollTo(0, 0);
						}
					});
					this.pageLoading = false
				}
			},

			//筛选数据
			async getInfor() {
				try {
					//获取类目
					let result4 = await categoryList()
					this.formatCategoryList(result4.data);
					this.CategorySelectItemList = result4.data;
				} catch (e) {
					console.log(e)
				} finally {
					this.startData();
				}
			},
			//格式化类目数据
			formatCategoryList(array) {
				for (let i = 0; i < array.length; i++) {
					if (array[i].categoryLevel == 3) {
						delete array[i].childCategoryList;
					} else {
						this.formatCategoryList(array[i].childCategoryList);
					}
				}
			},
			// 点击上传规格图片
			showImages(indexs, index) {
				this.dataId = {
					indexs: indexs,
					index: index
				};
			},
			// 上传规格图成功钩子
			handleAvatarSuccess1(res) {
				this.ruleForm.brandLogo = res.data[0];
				return true;
			},
			// 上传规格图前钩子
			beforeAvatarUpload1(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
				}
				if (!isLt3M) {
					this.$message.error('图片大小请控制在3M以内!');
				}
				return isJPG && isLt3M;
			},
			// 批量上传的图片
			transPictureFun(datas) {
				this.ruleForm.goodsImgUrls = datas;
			},
			//上传视频成功钩子
			handleVideoSuccess(res) {
				this.ruleForm.mainVideoUrl = res.data[0];
				this.loading = false;
			},
			// 上传视频前的钩子
			beforeUploadVideo(file) {
				const isLt30M = file.size / 1024 / 1024 < 30;
				if (['video/mp4'].indexOf(file.type) == -1) {
					this.$message.error('请上传正确的视频格式');
					return false;
				}
				if (!isLt30M) {
					this.$message.error('主图视频请控制在30M以内');
					return false;
				}
				this.loading = true;
			},
			//上传视频成功回调
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = file.percentage.toFixed(0);
				console.log(fileList);
			},

			//生成唯一ID
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			},

			// 保存商品信息
			submitForm() {
				let _this = this;
				//图片最多10张
				if (this.ruleForm.goodsImgUrls.length > 10) {
					this.ruleForm.goodsImgUrls.splice(10)
				}
				//处理推手收益数据，前者不能小于后者
				for (let i = 0; i < this.ruleForm.pusherProfitConfigs.length - 1; i++) {
					if (Number(this.ruleForm.pusherProfitConfigs[i].selfSaleProfit) > Number(this.ruleForm.pusherProfitConfigs[i + 1].selfSaleProfit)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: this.ruleForm.pusherProfitConfigs[i + 1].pusherLabel + '自营收益不能低于' + this.ruleForm.pusherProfitConfigs[
								i].pusherLabel + '自营收益！'
						});
						return;
					}
				}

                //临时添加等级1和等级2
                let tempPusherProfitConfigs = [
                    {
                        pusherLabel: '会员卡',
                        pusherLevel: '1',
                        selfSaleProfit: this.ruleForm.pusherProfitConfigs[0].selfSaleProfit,
                        pushSaleProfit: this.ruleForm.pusherProfitConfigs[0].pushSaleProfit,
                    },
                    {
                        pusherLabel: '银卡',
                        pusherLevel: '2',
                        selfSaleProfit: this.ruleForm.pusherProfitConfigs[0].selfSaleProfit,
                        pushSaleProfit: this.ruleForm.pusherProfitConfigs[0].pushSaleProfit,
                    },
                ];
                tempPusherProfitConfigs = tempPusherProfitConfigs.concat(this.ruleForm.pusherProfitConfigs);
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
                        //处理划线价的问题
                        if (Number(this.ruleForm.originalPrice) < Number(this.ruleForm.salePrice)) {
                            this.$message({
                                showClose: true,
                                type: 'error',
                                message: '划线价不能小于销售价'
                            });
                            return;
                        }
						this.loading = true
						try {
							//组织需要提交的数据
							let data = {
                                "isVirtual":true,
                                "outGoodsType":this.ruleForm.outGoodsType,
								"advertisementWords": this.ruleForm.advertisementWords,
								"afterSaleServiceSettings": "",
								"barCode": this.ruleForm.barCode,
								"outGoodsId": this.ruleForm.outGoodsId,
								"outSkuId": this.ruleForm.outSkuId,
								"costPrice": this.ruleForm.costPrice,
								"freightCalMethod": this.ruleForm.freightCalMethod,
								"freightPrice": this.ruleForm.freightPrice,
								"freightTemplateId": this.ruleForm.freightTemplateId,
								"goodSpecsList": [],
								"goodsCode": this.ruleForm.goodsCode,
								"goodsId": '',
								"goodsImgUrls": this.ruleForm.goodsImgUrls,
								"brandLogo": this.ruleForm.brandLogo,
								"goodsName": this.ruleForm.goodsName,
								"goodsSpecType": 1,
								"isEnableShoppingCard": this.ruleForm.isEnableShoppingCard,
								"isShowStock": this.ruleForm.isShowStock,
								"isShowSales": this.ruleForm.isShowSales,
								"mainImgUrl": this.ruleForm.goodsImgUrls[0],
								"mainVideoUrl": this.ruleForm.mainVideoUrl,
								"originalPrice": this.ruleForm.originalPrice,
								"pusherProfitConfigs": tempPusherProfitConfigs,
								"saleLimitType": this.ruleForm.saleLimitType,
								"salePrice": this.ruleForm.salePrice,
								"skuAddRequestList": [],
								"startSaleTimeType": this.ruleForm.startSaleTimeType,
								"stockReduceMethod": this.ruleForm.stockReduceMethod,
								"supplierId": this.ruleForm.supplierId,
								"thumbImgUrl": this.ruleForm.goodsImgUrls[0],
								"timeLimitEndTime": "",
								"timeLimitStartTime": "",
								"totalStock": this.ruleForm.totalStock,
								"unit": this.ruleForm.unit,
								"warningStock": this.ruleForm.warningStock,
								"goodsDesc": this.ruleForm.goodsDesc,
								"virtualSoldCount": this.ruleForm.virtualSoldCount,
								"saleLimitQuantity": this.ruleForm.saleLimitQuantity,
								"isOverseas": this.ruleForm.isOverseas,
								"isNeedCardImg": this.ruleForm.isNeedCardImg,
								"manageAwardFlag": this.ruleForm.manageAwardFlag, //是否计算管理奖 0否 1是
								"familyAwardFlag": this.ruleForm.familyAwardFlag, //是否计算家族奖 0否 1是
								"manageProfitConfig": this.ruleForm.manageProfitConfig / 100, //商品在此订单详情的管理奖励配置
							}
							data.timingOffTime = '';
							if (this.ruleForm.endSaleTimeType) {
								data.timingOffTime = this.ruleForm.timingOffTime;
							}
							data.timingSaleStartTime = '';
							if (this.ruleForm.startSaleTimeType == 2) {
								data.timingSaleStartTime = this.ruleForm.timingSaleTime;
							}

							//处理售后服务
							if (this.ruleForm.afterSaleServiceSettings1) {
								data.afterSaleServiceSettings = 1;
							}
							if (this.ruleForm.afterSaleServiceSettings2) {
								data.afterSaleServiceSettings = data.afterSaleServiceSettings ? data.afterSaleServiceSettings + ',2' : '2'
							}
							if (this.ruleForm.afterSaleServiceSettings3) {
								data.afterSaleServiceSettings = data.afterSaleServiceSettings ? data.afterSaleServiceSettings + ',3' : '3'
							}

							//处理规格数据
							if (this.goodsSpecValueList.length > 0) {
								data.goodsSpecType = 2;
								let saveGoodsSpecValueList = JSON.parse(JSON.stringify(this.goodsSpecValueList));

								saveGoodsSpecValueList.map(item => {
									let specValues = [];
									let specImgUrls = [];
									item.specValues.map(specValue => {
										if (item.hasImg) {
											if (specValue.imgUrl) {
												specImgUrls.push(specValue.imgUrl);
											} else {
												specImgUrls.push(_this.ruleForm.goodsImgUrls[0]);
											}

										}
										specValues.push(specValue.specValueName);
									})
									item.specValues = specValues;
									item.specImgUrls = specImgUrls;
								})
								data.goodSpecsList = saveGoodsSpecValueList;

								//处理规格明细数据
								let saveGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList));
								saveGoodsSpecList.map(item => {
									item.specValues = [];
									saveGoodsSpecValueList.map(saveGoodsSpecValue => {
										item.specValues.push(item[saveGoodsSpecValue.guid]);
									})
								})
								data.skuAddRequestList = saveGoodsSpecList;
							}
							//限时限购时间处理
							if (this.ruleForm.timeLimitTime) {
								data.timeLimitStartTime = this.ruleForm.timeLimitTime[0];
								data.timeLimitEndTime = this.ruleForm.timeLimitTime[1];
							}
							let result = '';
							if (this.$route.query.id > 0) {
								data.goodsId = this.$route.query.id;
								result = await productEdit(data);
							} else {
								result = await productSave(data);
							}



							if (result.success) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功'
								});

								this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
									(item) => {
										return item.path != this.$route.path;
									}
								);
								this.$router.push({
									path: '/goods/cps/cpsGoodsList'
								});
							} else {
								this.$message({
									showClose: true,
									type: 'error',
									message: result.alertMsg
								});
							}
						} catch (e) {
							console.log(e)
							this.loading = false
						} finally {
							setTimeout(() => {
								this.loading = false
							}, 600)
						}
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请填写完整的商品信息'
						});
						return false;
					}
				});
			},

			// 取消操作
			canselGoods() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
						(item) => {
							return item.path != this.$route.path;
						}
					);
					this.$router.push({
						path: '/goods/cps/cpsGoodsList'
					});
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				})
			},
		},
	}
</script>

<style lang="less">
	.creatGoodDetails {
		.videoBox {
			position: relative;

			.deletIcon {
				width: 152px;
				height: 152px;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.4);
				cursor: pointer;

				.deleteImg {
					position: absolute;
					font-size: 20px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.Displayblock {
				display: block;
			}

			.Displaynone {
				display: none;
			}
		}

		.priceNum {
			color: blue;
			cursor: pointer;
			margin-right: 20px;
		}

		.baseInformtion {
			width: 100%;
			background: #fff;
			padding: 10px;
			margin-bottom: 12px;

			.infortion {
				margin-bottom: 20px;
				padding: 10px 0;
				border-bottom: 1px solid #ccc;
			}
		}

		.textColor {
			color: #909399;
			font-size: 12px;
		}

		.spec-bos {
			background: #f0f2f5;
			padding: 5px 0;
		}

		.speceBos:last-child {
			margin-bottom: 30px;
		}

		.addSpece {
			color: blue;
			font-size: 15px;
			margin-left: 30px;
			cursor: pointer;
		}

		.close-bosx {
			position: absolute;
			right: -5px;
			top: -8px;
			font-size: 18px;
		}

		.close-bosx1 {
			position: absolute;
			right: 10px;
			top: 50%;
			font-size: 18px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
		}

		.close-bosx2 {
			position: absolute;
			right: -5px;
			top: -3px;
			font-size: 18px;
		}

		input[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.quill-editor:not(.bubble) .ql-container,
		.quill-editor:not(.bubble) .ql-container .ql-editor {
			min-height: 300px;
			padding-bottom: 20px;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

		.el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.el-upload:hover {
			border-color: #409eff;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 152px;
			height: 152px;
			line-height: 152px;
			text-align: center;
		}

		.specparentbox {
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.filter-container {
				padding-bottom: 0;
			}

			/deep/tbody {
				background: #fff;
			}

			/deep/.el-table__fixed-header-wrapper {
				background: #f8f8f9;
			}
		}

		.speceBoxe {
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.filter-container .filter-item {
				vertical-align: top;
			}
		}

		.specparentbox {
			.filter-container .filter-item {
				margin: 0;
			}
		}

		.avatar {
			width: 150px;
			height: 150px;
			display: block;
		}

		.tablered {
			color: red;
			vertical-align: -3px;
			margin-right: 5px;
		}
	}
</style>
